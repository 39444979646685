


// Idea taken from:
// https://codepen.io/thebabydino/pen/JVEKvJ
.banner {
  margin-top: 1em;

  margin-left: calc(50% - 50vw);
  width: 100vw;
  // height: 62.5vmin;
  height: 25vmin;
  min-height: 15em;
  background: linear-gradient(#000, transparent, #000), var(--img) 50%/ cover;
  background-attachment: fixed;
  background-blend-mode: multiply;

  --img: url(/img/deck-01-1920x960.png);
}
