
.schedule-grid {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-areas:
  "title    description"
  "subtitle description";
}

.schedule-title {
  grid-area: title;
}

.schedule-subtitle {
  grid-area: subtitle;
}

.schedule-description {
  grid-area: description;
  align-self: center;
}
